<template>
  <div class="row">
    <div class="col-auto d-flex flex-column gap-1">
      <RouterLink :to="`/sale/${$route.params.id}/edit`">
        <button class="btn btn-white d-flex align-items-center py-50 pl-0">
          <div
            style="width: 36px; height: 36px;"
            class="d-flex align-items-center justify-content-center rounded mr-75 border text-secondary"
          >
            1
          </div>
          <span class="font-weight-bolder">Sale</span>
        </button>
      </RouterLink>
      <button class="btn btn-white d-flex align-items-center py-50 pl-0">
        <div
          style="width: 36px; height: 36px;"
          class="d-flex align-items-center justify-content-center rounded mr-75 bg-primary text-white"
        >
          2
        </div>
        <span class="font-weight-bolder text-primary">Produk</span>
      </button>
    </div>

    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="form-row align-items-center mb-2">
            <div class="col">
              <h3 class="mb-0">
                Daftar Produk <span v-if="list.length">({{ list.length }})</span>
              </h3>
            </div>
            <div class="col-auto">
              <input
                ref="upload"
                hidden="hidden"
                type="file"
                @change="onFileChange"
              >
              <button
                class="btn btn-light d-flex align-items-center"
                @click="uploadFile"
              >
                <feather-icon
                  icon="UploadIcon"
                  size="16"
                  class="mr-50"
                />
                Upload Data
              </button>
            </div>
            <div class="col-auto">
              <button class="btn btn-light d-flex align-items-center">
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                  class="mr-50"
                />
                Template
              </button>
            </div>
          </div>

          <div class="form-row align-items-center mb-2">
            <span class="col-auto">Tambah Produk:</span>

            <div class="col-md">
              <v-select
                v-model="selectedOption"
                label="name"
                class="w-fill-available"
                :options="searchOptions"
                :disabled="loadingUpload"
                @input="select"
                @search="searchProduct"
              >
                <template #option="item">
                  <div class="d-flex align-items-center text-truncate">
                    <div class="flex-grow-0">
                      <img
                        :src="item.images[0].image_url | thumbnail(40)"
                        alt="Product Image"
                        width="40px"
                        height="40px"
                        class="rounded border mr-75"
                      >
                    </div>
                    <div class="flex-grow-1 text-truncate">
                      <div class="text-truncate">
                        {{ item.name }}
                      </div>
                      <small>{{ item.merchant.name }}</small>
                    </div>
                  </div>
                </template>
                <!-- No options -->
                <template #no-options="{ search }">
                  <span v-if="search">Tidak ada hasil untuk "{{ search }}".</span>
                  <span v-else-if="loadingSearch">Memuat..</span>
                  <span v-else>Ketik untuk mencari...</span>
                </template>
              </v-select>
            </div>

            <div class="col-auto">
              <button
                class="btn btn-primary"
                :disabled="loadingUpload"
              >
                <feather-icon icon="PlusIcon" />
                Tambah
              </button>
            </div>
          </div>

          <hr>

          <div
            v-if="!list.length"
            class="p-5 text-muted text-center"
          >
            Belum ada produk yang ditambahkan.
          </div>

          <ul
            class="list-group list-group-flush"
          >
            <li
              v-for="(item, index) in listOrdered"
              :key="`product-${index}`"
              style="list-style: none"
            >
              <h3
                v-if="index === 0 || listOrdered[index - 1].merchant_name !== item.merchant_name"
                class="mt-2 mb-1"
              >
                {{ item.merchant_name }}
              </h3>

              <div class="card shadow-none rounded-lg mb-50">
                <div class="card-body py-0 px-0">
                  <div class="form-row">
                    <div class="col-auto d-flex flex-column align-items-center">
                      <div>
                        <img
                          :src="item.image_url | thumbnail(50)"
                          alt="Foto"
                          class="rounded-lg border w-50px"
                        >
                      </div>
                      <div class="mt-50">
                        <button
                          class="btn btn-icon rounded-circle"
                          :class="{ 'btn-warning': item.is_flagged, 'btn-flat-warning': !item.is_flagged }"
                          @click="flagItem(item)"
                        >
                          <feather-icon icon="FlagIcon" />
                        </button>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row align-items-center">
                        <div class="col">
                          {{ item.name }}
                        </div>
                        <div class="col-auto">
                          <button
                            class="btn btn-flat-danger btn-icon"
                            disabled
                            @click="deleteItem(item)"
                          >
                            <feather-icon icon="TrashIcon" />
                          </button>
                        </div>
                      </div>

                      <div class="card border mt-50 mb-2">
                        <div class="card-body pt-1 px-1 pb-50">
                          <div class="form-row mb-50 font-weight-light text-muted">
                            <div class="col">
                              Varian
                            </div>
                            <div class="col-2">
                              Event
                            </div>
                            <div class="col-2">
                              Harga Awal
                            </div>
                            <div class="col-2">
                              <div>Harga Event</div>

                              <b-input-group class="input-group-merge mt-50">
                                <b-input-group-prepend>
                                  <div class="input-group-text px-75 pr-50">
                                    Rp
                                  </div>
                                </b-input-group-prepend>
                                <b-form-input
                                  v-model="item.sale_price_bulk"
                                />
                                <!-- @input="changeSalePriceBulk(item)" -->
                              </b-input-group>
                            </div>
                            <div class="col-1 text-center">
                              Disc.
                            </div>
                          </div>

                          <div
                            v-for="(sku, skuIndex) in item.skus"
                            :key="`sku-${skuIndex}`"
                            class="border-top"
                          >
                            <div class="form-row align-items-center">
                              <div class="col">
                                <div v-if="sku.details && sku.details.length">
                                  <span
                                    v-for="(detail, detailIndex) in sku.details"
                                    :key="`sku-${skuIndex}-detail-${detailIndex}`"
                                    class="font-weight-bold"
                                  ><span v-if="detailIndex > 0"> - </span>{{ detail.value }}</span>
                                  <span v-if="skuIndex === 0"> (Utama)</span>
                                </div>
                                <div
                                  v-else
                                  class="font-weight-bold"
                                >
                                  (Varian Utama)
                                </div>
                              </div>

                              <div class="col-2">
                                <b-form-checkbox
                                  v-model="sku.sale_price_enabled"
                                  name="check-button"
                                  switch
                                  inline
                                  :disabled="sku.sale_price_enabled_loading"
                                  @change="toggleSaleSku(sku)"
                                >
                                  Aktif<span v-if="sku.sale_price_enabled_loading">...</span>
                                </b-form-checkbox>
                              </div>

                              <div
                                class="col-2"
                                :class="{'opacity-0': !sku.sale_price_enabled}"
                              >
                                Rp{{ sku.price | number }}
                              </div>

                              <div class="col-2 py-25">
                                <b-input-group
                                  class="input-group-merge"
                                  :class="{'opacity-0': !sku.sale_price_enabled}"
                                >
                                  <b-input-group-prepend>
                                    <div class="input-group-text px-75 pr-50">
                                      <b-spinner
                                        v-if="sku.sale_price_loading"
                                        small
                                      />
                                      <span v-else>Rp</span>
                                    </div>
                                  </b-input-group-prepend>
                                  <b-form-input
                                    v-model="sku.sale_price"
                                    :disabled="!sku.sale_price_enabled || sku.sale_price_loading"
                                    @input="changeSalePrice(item)"
                                    @beforeinput="beforeChangeSalePrice(sku)"
                                    @keydown.enter.prevent="submitChangeSalePrice(sku)"
                                    @blur="submitChangeSalePrice(sku)"
                                  />
                                </b-input-group>
                              </div>

                              <div
                                class="col-1 text-center"
                                :class="{'opacity-0': !sku.sale_price_enabled}"
                              >
                                {{ Math.round((sku.price - sku.sale_price) / sku.price * 100) | number }}%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <b-modal
      v-model="showUploadDataModal"
      title="Upload Data"
      size="xl"
      body-class="p-0"
      ok-title="Upload"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      hide-footer
    >
      <div v-if="importedData.length">
        <CsvUploader
          :data="importedData"
          :header="importedHeader"
          :loading-submit="loadingUpload"
          @cancel="cancelUpload"
          @submit="submitUpload"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
// BCard,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormCheckbox,
  BModal, BSpinner,
} from 'bootstrap-vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import debounce from 'lodash/debounce'
import CsvUploader from '@/layouts/components/CsvUploader.vue'
import Papa from 'papaparse'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSpinner,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    CsvUploader,
    BModal,
  },
  data() {
    return {
      showUploadDataModal: false,
      loadingUpload: false,
      loadingProductList: false,
      selectedOption: null,
      searchOptions: [],
      importedData: [],
      importedHeader: [],
      loadingSearch: false,
      data: [],
      list: [],
      saleSkus: [],
    }
  },
  computed: {
    listOrdered() {
      return this.list.slice().sort((a, b) => {
        if (a.merchant_name < b.merchant_name) return -1
        if (a.merchant_name > b.merchant_name) return 1
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
    },
  },
  async mounted() {
    // window.addEventListener('beforeunload', this.handleBeforeUnload)

    this.loadingProductList = true
    // await this.loadProducts()
    this.loadingProductList = false

    // this.list = this.data.map(item => ({
    //   id: item.id,
    //   name: item.name,
    //   is_flagged: false,
    //   merchant_name: item.merchant.name,
    //   skus: item.variants.map(sku => ({
    //     ...sku,
    //     sale_price: sku.price,
    //     sale_price_enabled: true,
    //   })),
    //   image_url: item.images && item.images.length ? item.images[0].image_url : null,
    // }))
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  },
  methods: {
    async loadProducts() {
      const products = await this.$store.dispatch('sale/getProductList', this.$route.params.id)
      const saleSkus = await this.$store.dispatch('sale/getSaleSkus', this.$route.params.id)

      const data = []

      for (let productIndex = 0; productIndex < products.length; productIndex += 1) {
        const product = products[productIndex]
        const productSaleSku = saleSkus.filter(item => item.product_id === product.id)
        const currentProductSkus = product.variants

        data.push({
          id: product.id, // product id
          name: product.name,
          is_flagged: false,
          merchant_name: product.merchant.name,
          skus: currentProductSkus.map(item => {
            const saleSku = productSaleSku.find(saleSkuItem => item.id === saleSkuItem.sku.id)

            return ({
              ...item,
              product_id: product.id,
              sale_price: saleSku ? saleSku.sale_price : item.price,
              sale_price_enabled: !!saleSku,
            })
          }),
          image_url: product.images && product.images.length ? product.images[0].image_url : null,
        })
      }

      console.log(data)
      this.saleSkus = saleSkus
      this.list = data
    },
    async submitUpload(data) {
      await this.loadProducts()

      this.loadingUpload = true
      const currentSkuIds = this.saleSkus.map(item => item.sku.id)

      const newSkus = data.filter(item => !currentSkuIds.includes(item.id))
        .map(({ id, sale_price }) => ({ id, sale_price }))

      if (!newSkus.length) {
        this.loadingUpload = false
        this.showUploadDataModal = false
      }

      // eslint-disable-next-line no-unreachable
      const chunkSize = 500
      const skusChunks = []

      // Split data into chunks of 50 items
      for (let i = 0; i < newSkus.length; i += chunkSize) {
        skusChunks.push(newSkus.slice(i, i + chunkSize))
      }

      try {
        // Upload each chunk one by one
        // eslint-disable-next-line no-restricted-syntax
        for (const chunk of skusChunks) {
          // eslint-disable-next-line no-await-in-loop
          await this.$store.dispatch('sale/uploadSkus', {
            id: this.$route.params.id,
            data: { skus: chunk },
          })
        }
        this.showUploadDataModal = false
      } catch (error) {
        console.error('Error uploading SKUs:', error)
      } finally {
        this.loadingUpload = false
      }
    },
    uploadFile() {
      this.importedHeader = []
      this.importedData = []

      this.$refs.upload.click()
    },
    onParseComplete(result) {
      const { data } = result
      const headers = data[0]

      const columnIndex = {
        name: headers.indexOf('name'),
        variant: headers.indexOf('variant'),
        sale_price: headers.indexOf('sale_price'),
      }
      console.log(headers, columnIndex)
      const requiredHeaders = ['name', 'variant', 'sale_price']
      // headers = headers.filter(item => requiredHeaders.includes(item))

      // Validate headers
      if (!requiredHeaders.every(item => headers.includes(item))) {
        alert(`Invalid CSV template. Headers must be: ${requiredHeaders.join(', ')}`)
        return
      }

      const validData = []
      // eslint-disable-next-line no-restricted-syntax
      for (let index = 1; index < data.length; index += 1) {
        const row = {
          name: data[index][columnIndex.name],
          variant: data[index][columnIndex.variant],
          sale_price: data[index][columnIndex.sale_price],
        }

        if (!row.name) {
          alert(`Row ${index} rejected: 'name' column cannot be empty.`)
          return
        }

        if (row.sale_price === undefined || row.sale_price === null || row.sale_price === '') {
          row.is_sale_price_empty = true
        }

        row.variant = row.variant ? row.variant : null

        // Convert price fields to integers, removing non-numeric characters
        row.sale_price = row.sale_price
          ? parseInt(row.sale_price.replace(/[^0-9]/g, ''), 10)
          : null

        if (row.sale_price && Number.isNaN(row.sale_price)) {
          alert(`Row ${index} rejected: Price columns must contain valid whole number.`)
          console.log(row.sale_price)
          return
        }

        validData.push(row)
      }

      if (validData.length === 0) {
        alert('No valid data to import.')
        return
      }

      console.log(validData)

      this.importedHeader = headers
      this.importedData = validData
      this.showUploadDataModal = true
    },
    cancelUpload() {
      this.showUploadDataModal = false
    },
    onFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        // eslint-disable-next-line no-param-reassign
        event.target.value = null
        Papa.parse(file, {
          complete: this.onParseComplete,
          header: false,
        })
      }
    },
    handleBeforeUnload(e) {
      const message = 'Perubahan yang Anda lakukan mungkin belum tersimpan.'
      e.preventDefault()
      e.returnValue = message
      return message
    },
    deleteItem(item) {
      const index = this.list.findIndex(listItem => listItem.id === item.id)

      this.list.splice(index, 1)
    },
    flagItem(item) {
      const index = this.list.findIndex(listItem => listItem.id === item.id)

      const tempList = [...this.list]
      tempList[index].is_flagged = !tempList[index].is_flagged

      this.list = tempList
    },
    changeSalePriceBulk(item) {
      const index = this.list.findIndex(listItem => listItem.id === item.id)

      const tempList = [...this.list]
      tempList[index].skus = tempList[index].skus.map(sku => ({
        ...sku,
        sale_price: item.sale_price_bulk,
      }))

      this.list = tempList
    },
    changeSalePrice(item) {
      if (!item.sale_price_bulk) {
        return
      }

      const index = this.list.findIndex(listItem => listItem.id === item.id)

      const tempList = [...this.list]

      tempList[index].sale_price_bulk = ''

      this.list = tempList
    },
    beforeChangeSalePrice(sku) {
      const list = [...this.list]
      const productIndex = list.findIndex(item => item.id === sku.product_id)

      if (productIndex === -1) return

      const skuIndex = list[productIndex].skus.findIndex(item => item.id === sku.id)

      if (list[productIndex].skus[skuIndex].__previous_sale_price) {
        return
      }

      list[productIndex].skus[skuIndex].__previous_sale_price = list[productIndex].skus[skuIndex].sale_price
      this.list = list
    },
    submitChangeSalePrice(sku) {
      if (sku.__previous_sale_price === undefined
        || sku.__previous_sale_price === sku.sale_price) {
        console.log('ignore')
        return
      }

      this.submitChangeSkusSalePrice([sku])
    },
    submitChangeSkusSalePrice(skus) {
      let list = [...this.list]

      skus.forEach(sku => {
        const productIndex = list.findIndex(item => item.id === sku.product_id)

        if (productIndex === -1) return

        const skuIndex = list[productIndex].skus.findIndex(item => item.id === sku.id)

        if (list[productIndex].skus[skuIndex].__previous_sale_price === undefined
          || list[productIndex].skus[skuIndex].__previous_sale_price === list[productIndex].skus[skuIndex].sale_price) {
          console.log('ignore')
          return
        }

        list[productIndex].skus[skuIndex].sale_price_loading = true
      })

      this.$store.dispatch('sale/changeSaleSkusPrice', {
        id: this.$route.params.id,
        skus: skus.map(({ id, sale_price }) => ({ id, sale_price })),
      })
        .then(() => {
          skus.forEach(sku => {
            const productIndex = list.findIndex(item => item.id === sku.product_id)

            if (productIndex === -1) return

            const skuIndex = list[productIndex].skus.findIndex(item => item.id === sku.id)

            list[productIndex].skus[skuIndex].__previous_sale_price = list[productIndex].skus[skuIndex].sale_price
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sukses',
              icon: 'CheckIcon',
              text: 'Harga berhasil diperbaharui',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          skus.forEach(sku => {
            const productIndex = list.findIndex(item => item.id === sku.product_id)

            if (productIndex === -1) return

            const skuIndex = list[productIndex].skus.findIndex(item => item.id === sku.id)

            // do not need to replace this.list because sale_price is inside v-model
            list[productIndex].skus[skuIndex].sale_price = list[productIndex].skus[skuIndex].__previous_sale_price
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'BellIcon',
              text: 'Harga gagal diperbaharui',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          list = [...this.list]

          skus.forEach(sku => {
            const productIndex = list.findIndex(item => item.id === sku.product_id)

            if (productIndex === -1) return

            const skuIndex = list[productIndex].skus.findIndex(item => item.id === sku.id)

            delete list[productIndex].skus[skuIndex].sale_price_loading
          })

          this.list = list
        })
      this.list = list
    },
    async select(item) {
      const index = this.list.findIndex(product => product.id === item.id)

      if (index !== -1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Produk duplikat',
            icon: 'BellIcon',
            text: 'Produk ini telah ditambahkan ke promo',
            variant: 'warning',
          },
        })

        this.selectedOption = null
        return
      }

      const product = {
        id: item.id, // product id
        name: item.name,
        is_flagged: false,
        merchant_name: item.merchant.name,
        skus: item.variants.map(sku => ({
          ...sku,
          product_id: item.id,
          sale_price: sku.price,
          sale_price_enabled: true,
        })),
        image_url: item.images && item.images.length ? item.images[0].image_url : null,
      }

      this.list.unshift(product)

      this.selectedOption = null
      this.loadingUpload = true

      await this.addSku(product.skus)

      this.loadingUpload = false
      this.selectedOption = null
    },
    searchProduct: debounce(function search(input, setLoading) {
      if (!input) {
        this.searchOptions = []
        return
      }
      this.loadingSearch = true
      setLoading(true)

      this.$store.dispatch('product/searchOld', input).then(data => {
        this.searchOptions = data
      })
        .finally(() => {
          this.loadingSearch = false
          setLoading(false)
        })
    }, 500),
    deleteSku(sku) {
      let list = [...this.list]
      const productIndex = list.findIndex(item => item.id === sku.product_id)

      if (productIndex === -1) return

      const skuIndex = list[productIndex].skus.findIndex(item => item.id === sku.id)

      list[productIndex].skus[skuIndex].sale_price_enabled_loading = true
      this.list = list

      this.$store.dispatch('sale/deleteSaleSku', {
        saleId: this.$route.params.id,
        skuId: list[productIndex].skus[skuIndex].id,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sukses',
              icon: 'CheckIcon',
              text: 'Varian ini dihapus dari promo',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          // do not need to replace this.list because sale_price_enabled is inside v-model
          list[productIndex].skus[skuIndex].sale_price_enabled = !sku.sale_price_enabled

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'BellIcon',
              text: 'Varian ini digagal hapus dari promo',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          list = [...this.list]

          delete list[productIndex].skus[skuIndex].sale_price_enabled_loading
          this.list = list
        })
    },
    addSku(skus) {
      let list = [...this.list]

      skus.forEach(sku => {
        const productIndex = list.findIndex(item => item.id === sku.product_id)

        if (productIndex === -1) return

        const skuIndex = list[productIndex].skus.findIndex(item => item.id === sku.id)

        list[productIndex].skus[skuIndex].sale_price_enabled_loading = true
      })

      this.list = list

      return this.$store.dispatch('sale/createSaleSkus', {
        id: this.$route.params.id,
        skus: skus.map(({ id, sale_price }) => ({ id, sale_price })),
      })
        .then(data => {
          console.log(data)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sukses',
              icon: 'CheckIcon',
              text: 'Berhasil menambahkan variant ke promo',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          skus.forEach(sku => {
            const productIndex = list.findIndex(item => item.id === sku.product_id)

            if (productIndex === -1) return

            const skuIndex = list[productIndex].skus.findIndex(item => item.id === sku.id)

            // do not need to replace this.list because sale_price_enabled is inside v-model
            list[productIndex].skus[skuIndex].sale_price_enabled = !sku.sale_price_enabled
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'BellIcon',
              text: 'Varian gagal ditambahkan ke promo',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          list = [...this.list]

          skus.forEach(sku => {
            const productIndex = list.findIndex(item => item.id === sku.product_id)

            if (productIndex === -1) return

            const skuIndex = list[productIndex].skus.findIndex(item => item.id === sku.id)

            delete list[productIndex].skus[skuIndex].sale_price_enabled_loading
          })

          this.list = list
        })
    },
    toggleSaleSku(sku) {
      if (sku.sale_price_enabled) {
        console.log('add')
        this.addSku([sku])
      } else if (!sku.sale_price_enabled) {
        console.log('delete')
        this.deleteSku(sku)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.sortable-ghost {
  opacity: 0;
  transition-duration: 0s;
}
</style>
