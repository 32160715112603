<template>
  <div>
    <table
      v-if="header.length > 0"
      class="table mt-0"
    >
      <thead>
        <tr>
          <th>No.</th>
          <th class="px-0" />
          <th>Produk</th>
          <th>Varian</th>
          <th class="text-nowrap">
            Harga Awal
          </th>
          <th class="text-nowrap">
            Harga Sale
          </th>
          <th>Persentase</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in tempData"
          :key="index"
          :class="{
            'table-danger': item.is_sale_price_empty
              || item.is_product_not_found
              || item.is_sku_not_found
          }"
        >
          <td>{{ index + 1 }}.</td>

          <td class="px-0">
            <feather-icon
              v-if="item.is_product_not_found"
              v-b-tooltip="'Produk tidak ditemukan'"
              icon="XCircleIcon"
              class="text-danger"
              size="20"
            />
            <feather-icon
              v-else-if="item.is_sku_not_found"
              v-b-tooltip="'Varian tidak ditemukan'"
              icon="XCircleIcon"
              class="text-danger"
              size="20"
            />
            <feather-icon
              v-else-if="item.is_sale_price_empty"
              v-b-tooltip="'Harga sale kosong'"
              icon="XCircleIcon"
              class="text-danger"
              size="20"
            />
            <feather-icon
              v-else
              icon="CheckCircleIcon"
              class="text-success"
              size="20"
            />
          </td>

          <td>
            <div class="form-row align-items-center">
              <div class="col-auto">
                <img
                  v-if="item.image_url"
                  :src="item.image_url | thumbnail(40)"
                  class="rounded"
                  alt="Foto"
                >
                <div
                  v-else
                  class="rounded"
                  style="background: #888888; opacity: 0.2; width: 40px; height: 40px"
                />
              </div>

              <div class="col s-12">
                {{ item.name }}
              </div>
            </div>
          </td>

          <td>
            <span
              v-if="item.variant"
              class="text-nowrap"
            >{{ item.variant }}</span>
            <span
              v-else
              class="opacity-50"
            >●</span>
          </td>

          <td>
            <div v-if="item.price">
              <div class="text-nowrap">
                Rp {{ item.price | number }}
              </div>
              <div
                v-if="item.compare_price"
                class="mt-n25 opacity-50"
              >
                <small class="text-line-through">Rp {{ item.compare_price | number }}</small>
              </div>
            </div>
            <i
              v-else-if="currentIndex > index"
              class="text-danger"
            >Gagal</i>
            <i
              v-else
              class="opacity-50"
            >Memuat...</i>
          </td>

          <td>
            <b-input-group
              class="input-group-merge"
            >
              <b-input-group-prepend>
                <div class="input-group-text px-75 pr-50">
                  Rp
                </div>
              </b-input-group-prepend>
              <b-form-input
                v-model="item.sale_price"
                placeholder="●"
                @blur="onSalePriceChanged(index)"
              />
            </b-input-group>
          </td>

          <td>
            <span
              v-if="item.sale_price && item.highest_price"
              class="text-nowrap"
            >{{ Math.round((item.sale_price / item.highest_price) * 100) | number }}%</span>
            <span
              v-else-if="item.highest_price"
              class="opacity-50"
            >●</span>
            <i
              v-else-if="currentIndex > index"
              class="text-danger"
            >Gagal</i>
            <i
              v-else
              class="opacity-50"
            >Memuat...</i>
          </td>

          <td>
            <button
              class="btn btn-flat-danger btn-icon"
              @click="deleteItem(index)"
            >
              <feather-icon icon="TrashIcon" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="px-2 py-1">
      <div class="form-row justify-content-end">
        <div class="col-auto">
          <button
            :disabled="loadingSubmit"
            class="btn btn-light"
            @click="cancel"
          >
            Cancel
          </button>
        </div>
        <div class="col-auto">
          <button
            :disabled="preventSubmit || loadingSubmit"
            class="btn btn-primary d-flex align-items-center"
            @click="submit"
          >
            <b-spinner
              v-if="loadingSubmit"
              class="mr-50"
              small
            />
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BInputGroupPrepend, BInputGroup, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  props: {
    header: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tempData: this.data,
      fetchedProducts: [],
      currentIndex: 0,
    }
  },
  computed: {
    preventSubmit() {
      return this.tempData.filter(item => item.is_sale_price_empty
        || item.is_product_not_found
        || item.is_sku_not_found).length > 0
    },
  },
  mounted() {
    this.loadProducts()
  },
  methods: {
    submit() {
      this.$emit('submit', this.tempData)
    },
    cancel() {
      this.$emit('cancel')
    },
    deleteItem(index) {
      this.tempData.splice(index, 1)
    },
    onSalePriceChanged(index) {
      const row = this.tempData[index]
      const salePrice = row.sale_price
      const highestPrice = row.highest_price

      const isSalePriceEmpty = !salePrice ? true : undefined
      const isSalePriceHigher = salePrice >= highestPrice ? true : undefined

      const item = {
        ...row,

        is_sale_price_empty: isSalePriceEmpty,
        is_sale_price_higher: isSalePriceHigher,
      }

      this.tempData.splice(index, 1, item)
    },
    async loadProducts() {
      const names = [...new Set(this.tempData.map(item => item.name))]
      const chunkSize = 50
      const nameChunks = []

      // Split names into chunks of 50 items
      for (let i = 0; i < names.length; i += chunkSize) {
        nameChunks.push(names.slice(i, i + chunkSize))
      }

      const allSuggestions = []

      try {
        // Process each chunk one by one
        // eslint-disable-next-line no-restricted-syntax
        for (const chunk of nameChunks) {
          // eslint-disable-next-line no-await-in-loop
          const suggestions = await this.$store.dispatch('product/searchSuggestionOld', chunk)
          allSuggestions.push(...suggestions)
        }

        // Combine all suggestions into a single array
        this.fetchedProducts = allSuggestions
      } catch (error) {
        console.error('Error fetching product suggestions:', error)
      }

      // this.fetchedProducts = await this.$store.dispatch('product/searchSuggestionOld', names)

      for (; this.currentIndex < this.tempData.length; this.currentIndex += 1) {
        const product = this.fetchedProducts.length
          ? this.fetchedProducts.find(item => item.name === this.tempData[this.currentIndex].name)
          : null

        // product not found
        if (!product) {
          this.tempData[this.currentIndex].is_product_not_found = true
          // eslint-disable-next-line no-continue
          continue
        }

        const sku = product.skus
          ? product.skus.find(item => item.label === this.tempData[this.currentIndex].variant)
          : null

        // product found, sku not found
        if (!sku) {
          this.tempData[this.currentIndex].is_sku_not_found = true

          this.tempData[this.currentIndex].image_url = product.image_url

          // eslint-disable-next-line no-continue
          continue
        }

        this.tempData[this.currentIndex].id = sku.id
        this.tempData[this.currentIndex].price = sku.price
        this.tempData[this.currentIndex].compare_price = sku.compare_price
        this.tempData[this.currentIndex].highest_price = sku.compare_price
          ? sku.compare_price
          : sku.price

        this.tempData[this.currentIndex].image_url = sku.image_url || product.image_url
      }
    },
  },
}
</script>

<style scoped>
.table {
  margin-top: 20px;
}
</style>
